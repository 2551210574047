import React, { useEffect, useState } from 'react'
import LoginSidebar from './LoginSidebar'
import Modal from 'react-bootstrap/Modal';
import Loader from '../../utils/Loader';
import ApiConnection from '../../utils/ApiConnection';
import moment from 'moment';
import { toast } from 'react-toastify';
import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import useLoadScript from './useLoadScript';


const NewRide = () => {

    const [loading, setloading] = useState(false)
    const [triplist, settriplist] = useState([])
    const [cancelmodal, setcancelmodal] = useState(false);
    const [rowId, setrowId] = useState('')
    const [reason, setreason] = useState('')
    const handleClose = () => setcancelmodal(false);
    const [directions, setDirections] = useState(null)
    const [isshow, setisshow] = useState(null)


    const GetTrip = async () =>{

        setloading(true)

        try {

            const response = await ApiConnection.get('trips?status=new')
            setloading(false)
            if(response?.status === 200) {
                settriplist(response?.data?.data?.list)
            }

        } catch (e){  setloading(false)}

    }

    useEffect(()=>{
        GetTrip()
    },[])


    const cancelHandle = async (id) =>{
        setrowId(id)
        setcancelmodal(true)
    }

    const SubmitHandle = async () =>{
        if(reason == ''){
            toast.error('Your coments is mandatory')
        } else {
            setloading(true)
            var FormData = require('form-data');
            var data = new FormData();
            data.append('id', rowId);
            data.append('action', 'cancel');
            data.append('decline_reason', reason);
            

            try{
                const response = await ApiConnection.post('trips/change-status', data)
                setloading(false)
                if(response?.data?.success){
                    setcancelmodal(false)
                    toast.success(response?.data?.message)
                    GetTrip()
                }

            } catch(e){  setloading(false)}
        }
    }



 
    const containerStyle = {
        width: '100%',
        height: '200px',
      };

   

    

      function ViewHandle (id, plat, plng, dlat, dlng){

        var origin = {lat:plat, lng: plng }
        var destination = {lat:dlat, lng: dlng }

        setisshow(id)
        if (origin && destination) {
          const directionsService = new window.google.maps.DirectionsService();
          directionsService.route(
            {
              origin: origin,
              destination: destination,
              travelMode: window.google.maps.TravelMode.DRIVING,
            },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                setDirections(result);
              } else {
                console.error(`error fetching directions ${result}`);
              }
            }
          );
        }
      }

      const isScriptLoaded = useLoadScript('AIzaSyBrRtkwvBcSh3_uISG8CVAX2IqykHdQEP4');


  return (
 
    <div className='afterlog-sec'>

    {loading && <Loader/>}
    
    
        <div className='container'>
            <div className='row'>
                <div className='col-lg-3'>
                    <LoginSidebar />
                </div>
                <div className='col-lg-9'>
                <div className='afterlog-sec-right'>
                <h2 className='headingStyle text-left'>New Ride Requests</h2>
                {triplist.length > 0 ? 
                triplist&&triplist.map((item, i)=>{
                    return (
                        <div className='ride-list' key={i}>
                        <ul>
                            <li>
                                <p><b>Pickup Date: </b> {moment(item?.pickup_date).format('DD-MM-YYYY')} </p>
                            </li>
                            <li>
                                <p><b>Pickup Time: </b> {moment(item?.pickup_date).format('LT')} </p>
                            </li>
                            <li>
                                <p><b>Pickup Location: </b> {item?.pickup_location}  </p>
                            </li>
                            <li>
                                <p><b>Drop Location: </b> {item?.drop_location}  </p>
                            </li>
                            <li>
                                <p><b>Number of Stops: </b> {item?.stops.length} </p>
                            </li>
                            <li>
                                <p><b>Estimated Fare: </b>  $ {item?.estimated_fare} </p>
                            </li>
                        </ul>
                        <div className='ride-button mb-2'>
                    
                            <button className='btn btn-sm btn-danger' onClick={()=>cancelHandle(item?.id)}>Cancel Trip</button>
                            <button className='btn btn-info btn-sm ml-2' onClick={()=>ViewHandle(item?.id, Number(item?.pickup_lat),
                      Number(item?.pickup_long),Number(item?.drop_lat), Number(item?.drop_long)
                      
                      )} >View</button>
                    
                        </div>
                        {
                        isshow == item.id && 
                 
                        <GoogleMap mapContainerStyle={containerStyle}  center={{
                          lat: (Number(item?.pickup_lat) + Number(item?.drop_lat)) / 2,
                          lng: (Number(item?.pickup_long) + Number(item?.drop_long)) / 2,
                        }} zoom={9}>
                                      {/* <Marker position={{lat:Number(item?.pickup_lat), lng:Number(item?.pickup_long)}} />
                                      <Marker position={{lat:Number(item?.drop_lat), lng:Number(item?.drop_long)}} /> */}
                                      <DirectionsRenderer directions={directions} />
                                     
                                    </GoogleMap>
                                
                      }
                    </div>
                    )
                })
            
                :
                <h5>No Record found</h5>
            
            }
                  
                  
                </div>  
            </div>  
            </div>  
        </div>
        <Modal
            show={cancelmodal}
            centered
            onHide={handleClose}
          >
     <Modal.Header closeButton>
              <Modal.Title>Select Cancellation Reason </Modal.Title>
            </Modal.Header>
            <Modal.Body className='text-left py-2'>
                {/* <div className='form-group'>
                    <label>Reason For</label>
                    <select className='form-control'>
                        <option>--Select--</option>
                    </select>
                </div> */}
                <div className='form-group'>
                    <label>Comments</label>
                    <textarea className='form-control' placeholder='Comments' value={reason} onChange={(e)=>setreason(e.target.value)}></textarea>
                </div>
                <div className='form-group text-right'>
                    <button className='btn btn-primary' onClick={SubmitHandle}>Submit</button>
                </div>
            </Modal.Body>
          
          </Modal>
    </div>
   
  )
}

export default NewRide